import React, { useEffect, useState, useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Menu from "../components/Menu";
import SessionOut from "../components/SessionOut";
import CalendarTurnos from "../components/CalendarTurnos";
import ToolTip from "../utils/ToolTip.js";
import { useRestaurant } from "../hooks/useRestaurant";

const Turno = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [token, setToken] = useState(null);
  const [sessionExpired, setSessionExpired] = useState(false);
  const [status, setStatus] = useState(null);
  const [mozos, setMozos] = useState([]);
  const [selectedMozos, setSelectedMozos] = useState([]);
  const [unselectedMozos, setUnselectedMozos] = useState([]);
  const [nextMozos, setNextMozos] = useState([]);
  const [montoEfectivo, setMontoEfectivo] = useState("");
  const [finalizarResponse, setFinalizarResponse] = useState(null);
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [turnoTiempo, setTurnoTiempo] = useState("");
  const [backup, setBackup] = useState(false);
  const [switchCount, setSwitchCount] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [nameFilter, setNameFilter] = useState("");
  const [selectedRole, setSelectedRole] = useState("");
  const [rolesName, setRolesName] = useState([]);
  const [efectivoEnabled, setEfectivoEnabled] = useState(true);
  const [efectivoValues, setEfectivoValues] = useState({});
  const [digitalValues, setDigitalValues] = useState(null);
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const currentToken = location.state?.token;
  const { getTurnoActivo, turnoActivo, getRolesData, roles } = useRestaurant({
    token: currentToken,
  });

  useEffect(() => {
    const efectivoPrevio = JSON.parse(localStorage.getItem("efectivo"));
    if (efectivoPrevio && Object.keys(efectivoPrevio).length > 0) {
      setEfectivoValues(efectivoPrevio);
    }
  }, []);

  useEffect(() => {
    if (Object.keys(efectivoValues).length > 0) {
      localStorage.setItem("efectivo", JSON.stringify(efectivoValues));
    }
  }, [efectivoValues]);

  const getMontoDigital = () => {
    const acumulado = {};
    const pagos = turnoActivo.pagos;
    if (!pagos) {
      return [];
    }
    pagos.forEach((pago) => {
      const { mozo_cuit, monto, fee_details } = pago;
      let fee = fee_details.reduce((acum, fee) => acum + fee.amount, 0);

      // Si el mozo_cuit ya está en el acumulado, suma el monto
      if (acumulado[mozo_cuit]) {
        acumulado[mozo_cuit] += monto - fee;
      } else {
        // Si no está, inicialízalo con el monto actual
        acumulado[mozo_cuit] = monto - fee;
      }
    });

    // Convierte el objeto acumulado en un array
    const resultado = Object.keys(acumulado).map((mozo_cuit) => ({
      mozo_cuit,
      monto_total: acumulado[mozo_cuit].toFixed(2),
    }));

    return resultado;
  };

  useEffect(() => {
    getTurnoActivo();
  }, []);

  useEffect(() => {
    if (turnoActivo) {
      const monto = getMontoDigital();
      setDigitalValues(monto);
    }
  }, [turnoActivo]);

  const checkTurnoActivo = useCallback(async () => {
    try {
      if (!currentToken) {
        return;
      }

      const response = await fetch("https://tippitqr.com/userdb/hay_activo", {
        method: "GET",
        headers: {
          Authorization: `Bearer ${currentToken}`,
        },
      });

      const data = await response.json();

      setStatus(data.status);

      if (response.status === 200) {
        setShowCreateForm(true);
      } else if (response.status === 401) {
        setSessionExpired(true);
      } else if (response.status === 400) {
        setShowCreateForm(false);
      } else {
        console.log("Error:", data);
      }
    } catch (error) {
      console.error("Error checking turno activo:", error);
    }
  }, [currentToken]);

  const fetchMozos = useCallback(async () => {
    try {
      const response = await fetch(
        "https://tippitqr.com/userdb/mozos_vinculados",
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${currentToken}`,
          },
        }
      );

      const data = await response.json();
      if (response.ok) {
        setMozos(data);
        const rolesFromMozos = data.map((grupo) => grupo.rol);
        setRolesName(rolesFromMozos);
      } else {
        console.log("Error fetching mozos:", data);
      }
    } catch (error) {
      console.error("Error fetching mozos:", error);
    }
  }, [currentToken]);

  useEffect(() => {
    let currentToken = location.state?.token;
    if (!currentToken) {
      currentToken = sessionStorage.getItem("token");
    } else {
      sessionStorage.setItem("token", currentToken);
    }

    if (!currentToken) {
      navigate("/");
    } else {
      setToken(currentToken);
    }
  }, [navigate, location.state]);

  useEffect(() => {
    getRolesData();
  }, [getRolesData]);

  const finalizarTurno = async () => {
    try {
      setIsLoading(true);
      const mozosString = selectedMozos.join(",");

      // Crear efectivoTrabajadores
      const allMozosCuit = mozos.flatMap((grupo) =>
        grupo.usuarios.map((mozo) => mozo.cuit)
      );
      const efectivoTrabajadores = {};
      const digitalTrabajadores = {};
      allMozosCuit.forEach((cuit) => {
        if (selectedMozos.includes(cuit)) {
          efectivoTrabajadores[cuit] = parseFloat(efectivoValues[cuit]) || 0;
          digitalTrabajadores[cuit] =
            parseFloat(
              digitalValues?.find((item) => item.mozo_cuit === cuit)
                ?.monto_total || 0
            ) || 0;
        } else {
          efectivoTrabajadores[cuit] = 0;
          digitalTrabajadores[cuit] = 0;
        }
      });

      const montoEfectivoFinal = Object.values(efectivoTrabajadores).reduce(
        (total, value) => total + value,
        0
      );

      const montoDigitalFinal = Object.values(digitalTrabajadores).reduce(
        (total, value) => total + value,
        0
      );

      if (process.env.NODE_ENV === "development") {
        console.log("Mozos:", mozosString);
        console.log("Monto Efectivo:", montoEfectivoFinal);
        console.log("Efectivo Trabajadores:", efectivoTrabajadores);
      }

      const porcentaje = roles.flatMap((rol) => ({
        nombre_rol: rol.nombre_rol,
        monto:
          (montoDigitalFinal + parseFloat(montoEfectivoFinal)) *
          (rol.porcentaje / 100),
      }));
      const bodyParams = {
        mozos: mozosString,
        mozos_siguiente_turno: nextMozos,
        monto_efectivo: parseFloat(montoEfectivoFinal),
        monto_digital: montoDigitalFinal,
        total: montoDigitalFinal + parseFloat(montoEfectivoFinal),
        digital_separado: JSON.stringify(digitalTrabajadores),
        efectivo_separado: JSON.stringify(efectivoTrabajadores),
        rendir: JSON.stringify(porcentaje),
      };
      console.log(bodyParams);
      const bodyData = new URLSearchParams(bodyParams).toString();

      const response = await fetch(
        "https://tippitqr.com/finalizar/turno/rock&feller",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: `Bearer ${token}`,
          },
          body: bodyData,
        }
      );

      const data = await response.json();
      if (response.status === 200) {
        localStorage.removeItem("efectivo");
        setFinalizarResponse(data);
        console.log(data);
        setStatus(data.mensaje);
        setTurnoTiempo("");
        localStorage.removeItem("Turno");
        setShowModal(true);
        setError(null);
      } else {
        console.log(data);
        setError(`${data.error}`);
      }
    } catch (error) {
      setError(`Error al finalizar el turno: ${error}`);
    } finally {
      setIsLoading(false);
    }
  };

  const rendirValue = (mozo) => {
    let resultado =
      ((parseFloat(
        digitalValues?.find((item) => item.mozo_cuit === mozo.cuit)?.monto_total
      ) || 0) +
        parseFloat(efectivoValues[mozo.cuit] || 0)) *
      0.3;
    return resultado.toFixed(2);
  };

  const rcValue = (mozo) => {
    let resultado =
      ((parseFloat(
        digitalValues?.find((item) => item.mozo_cuit === mozo.cuit)?.monto_total
      ) || 0) +
        parseFloat(efectivoValues[mozo.cuit] || 0)) *
      0.35;
    return resultado.toFixed(2);
  };

  useEffect(() => {
    checkTurnoActivo();
  }, [checkTurnoActivo]);

  useEffect(() => {
    if (digitalValues) {
      digitalValues.forEach((item) => {
        setUnselectedMozos((prevState) => {
          const isInEfectivoValues = efectivoValues.hasOwnProperty(item.mozo_cuit);
          return prevState.includes(item.mozo_cuit) || isInEfectivoValues
            ? prevState
            : [...prevState, item.mozo_cuit];
        });
      });
    }
  }, [digitalValues]);

  useEffect(() => {
    if (status === "Ya existe un turno activo") {
      fetchMozos();
    }
  }, [status, fetchMozos]);

  const crearTurno = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch("https://tippitqr.com/crear/turno", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `Bearer ${token}`,
        },
        body: new URLSearchParams({
          hora_fin: "12",
          backup: backup.toString(),
        }).toString(),
      });

      const data = await response.json();

      if (response.status === 200) {
        setStatus(data.status);
        setShowCreateForm(false);
        localStorage.setItem("Turno", new Date().toISOString());
      } else {
        console.log("Error al crear el turno:", data);
      }
    } catch (error) {
      console.error("Error creando turno:", error);
    } finally {
      window.location.reload();
    }
  };

  useEffect(() => {
    const calculateTimeElapsed = () => {
      const turnoInicio = localStorage.getItem("Turno");
      if (!turnoInicio) return;

      const turnoInicioDate = new Date(turnoInicio);
      const ahora = new Date();
      const diferencia = ahora - turnoInicioDate;

      const horas = Math.floor(diferencia / (1000 * 60 * 60));
      const minutos = Math.floor((diferencia % (1000 * 60 * 60)) / (1000 * 60));
      const segundos = Math.floor((diferencia % (1000 * 60)) / 1000);

      setTurnoTiempo(`${horas}h ${minutos}m ${segundos}s`);
    };

    calculateTimeElapsed();
    const interval = setInterval(calculateTimeElapsed, 1000);

    return () => clearInterval(interval);
  }, []);

  const handleSelectAllCheckboxChange = (e) => {
    const isChecked = e.target.checked;
    setSelectAllChecked(isChecked);
    if (isChecked) {
      const allCuits = mozos.flatMap((grupo) =>
        grupo.usuarios.map((mozo) => mozo.cuit)
      );
      setSelectedMozos(allCuits);
    } else {
      setSelectedMozos([]);
    }
  };

  const handleMozosChange = (value, cuit) => {
    if (value) {
      if (!isNaN(parseFloat(efectivoValues[cuit]))) {
        setSwitchCount((prevCount) => prevCount + 1);
        setSelectedMozos((prevState) =>
          prevState.includes(cuit) ? [...prevState] : [...prevState, cuit]
        );
        setUnselectedMozos((prevState) =>
          prevState.filter((item) => item !== cuit)
        );
      }
    } else {
      setSwitchCount((prevCount) => prevCount - 1);
      setSelectedMozos((prevState) =>
        prevState.filter((item) => item !== cuit)
      );
      if (digitalValues.find((item) => item.mozo_cuit === cuit)) {
        setUnselectedMozos((prevState) =>
          prevState.includes(cuit) ? [...prevState] : [...prevState, cuit]
        );
      }
    }
  };

  useEffect(() => {
    if (unselectedMozos) {
      console.log("unSelected: " + unselectedMozos);
      console.log("selected: " + selectedMozos);
    }
  }, [unselectedMozos, selectedMozos]);

  const handleNameFilterChange = (e) => {
    setNameFilter(e.target.value);
  };

  const handleRoleFilterChange = (e) => {
    setSelectedRole(e.target.value);
  };

  const handleEfectivoValueChange = (cuit, value) => {
    setUnselectedMozos((prevState) =>
      prevState.filter((item) => item !== cuit)
    );
    setEfectivoValues((prevValues) => ({
      ...prevValues,
      [cuit]: value,
    }));
  };

  useEffect(() => {
    if (efectivoEnabled) {
      setSelectedMozos((prevSelectedMozos) => {
        const cuitsWithValue = Object.entries(efectivoValues)
          .filter(([cuit, value]) => value !== "" && !isNaN(value))
          .map(([cuit, value]) => cuit);

        const newSelectedMozos = [
          ...new Set([...prevSelectedMozos, ...cuitsWithValue]),
        ];

        if (newSelectedMozos.length !== prevSelectedMozos.length) {
          setSwitchCount(newSelectedMozos.length);
        }

        return newSelectedMozos;
      });
      setUnselectedMozos((prevState) => {
        const cuitsWithValue = Object.entries(efectivoValues)
          .filter(([cuit, value]) => value !== "" && !isNaN(value))
          .map(([cuit, value]) => cuit);
        
        return prevState.filter((item) => !cuitsWithValue.includes(item));
      });
      
    }
  }, [efectivoValues, efectivoEnabled]);

  const isFinalizeButtonDisabled = () => {
    if (isLoading) return true;
    if (switchCount < 2) return true;

    if (efectivoEnabled) {
      for (let cuit of selectedMozos) {
        const value = efectivoValues[cuit];
        if (value === undefined || value === "" || isNaN(parseFloat(value))) {
          return true;
        }
      }
      return false;
    } else {
      if (montoEfectivo === "" || parseFloat(montoEfectivo) <= 0) {
        return true;
      }
      return false;
    }
  };

  const verificarTurno = () => {
    if (unselectedMozos.length > 0) {
      setNextMozos([]);
      setShowModal(true);
    } else {
      finalizarTurno();
    }
  };

  const handleNextTurnoCheckboxChange = (e, cuit) => {
    const isChecked = e.target.checked;
    if (isChecked) {
      setNextMozos((prevState) => [...prevState, cuit]);
    } else {
      setNextMozos((prevState) => prevState.filter((item) => item !== cuit));
    }
  };

  useEffect(() => {
    if (nextMozos) {
      console.log(nextMozos);
    }
  }, [nextMozos]);

  return (
    <div className="w-full min-h-screen bg-gradient-to-b from-pink-500 via-pink-200 to-gray-300 md:bg-none">
      <div className="flex items-left justify-left w-90 text-sm ">
        <div className="flex w-full">
          <Menu rol="Restaurante" token={token} />
          <div className="flex-1 w-full">
            <div
              className={`w-11/12 max-w-[900px] mb-10 pt-8 mt-10 px-8 pb-5 min-h-96 mx-auto bg-white shadow-lg shadow-gray-250 rounded-lg relative ${
                turnoTiempo
                  ? "border-solid border-2 border-red-500 max-w-[900px]"
                  : ""
              }`}
            >
              <h1 className="text-2xl font-semibold mb-2 flex items-center">
                Registro de Turnos
                <ToolTip
                  position="bottom"
                  width="w-80"
                  text="Esta herramienta te permite registrar y finalizar turnos para calcular la propina a repartir entre los mozos en partes iguales y proporcionales."
                />
              </h1>
              <p className="text-md md:text-lg">
                Antes de comenzar un turno, primero debes tener que terminar
                cualquier turno que este corriendo en la actualidad.
                {/* esta para el culo escrito esto de aca arriba ^ coma incluida mal puesta. Readaccion a marzo. */}
              </p>
              <h3 className="text-md font-bold mt-3 md:absolute top-3 right-8">
                Turnos en progreso:
                <span
                  className={`rounded-md md:ml-2 font-normal block md:inline ${
                    status === "Ya hay un turno activo"
                      ? "text-red-500 "
                      : "text-green-500"
                  }`}
                >
                  {status}
                  <span
                    className={`bg-red-500 text-white rounded-md text-xl font-semibold px-3 md:ml-3 inline-block min-w-[140px] text-center ${
                      turnoTiempo ? "" : "hidden"
                    }`}
                  >
                    {turnoTiempo}
                  </span>
                </span>
              </h3>

              <hr className="my-5 mx-[-30px]" />
              {showCreateForm && (
                <div className="mt-4">
                  <h3 className="text-lg mb-2 font-semibold">Nuevo turno</h3>
                  <form onSubmit={crearTurno}>
                    <div className="flex flex-row space-x-4 mb-4">
                      <div className="w-50 mr-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2">
                          Hora de cierre
                        </label>
                        <input
                          type="time"
                          name="hora_fin"
                          defaultValue="12:00"
                          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          required
                        />
                      </div>
                    </div>
                    <button
                      type="submit"
                      className="transition:all 
                            duration-300 
                            ease-in-out 
                            w-full 
                            px-2 
                            py-4 
                            text-white 
                            font-semibold 
                            text-lg
                            bg-[#FF5EA3] 
                            rounded-lg 
                            hover:bg-[#FF7DF2] 
                            p-3"
                    >
                      Comenzar nuevo turno
                    </button>
                    <p className="font-normal text-center mt-2 text-sm text-gray-500">
                      El sistema no realiza transacciones, solo ayuda a calcular
                      y visualizar cuánto le corresponde a cada mozo
                    </p>
                  </form>
                </div>
              )}

              {showModal && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-90 z-50">
                  <div className="bg-white pt-6 rounded-lg shadow-lg text-center w-6/12 max-w-96 min-w-[355px] p-4">
                    <h2 className="text-xl font-bold">
                      Transferencias Pendientes
                    </h2>
                    <p className="mb-4 text-md">
                      Existen transferencias pendientes. ¿Quieres agregarlas
                      también?
                    </p>
                    <div className="flex justify-center space-x-4">
                      <button
                        onClick={() => {
                          setBackup(true);
                          setShowModal(false);
                        }}
                        className="transition-all duration-300 ease-in-out px-4 py-2 text-white font-semibold bg-[#FF5EA3] rounded-lg hover:bg-[#FF7DF2] hover:scale-105"
                      >
                        Sí, agregarlas
                      </button>
                      <button
                        onClick={() => {
                          setBackup(false);
                          setShowModal(false);
                        }}
                        className="transition-all duration-300 ease-in-out px-4 py-2 text-white font-semibold bg-gray-500 rounded-lg hover:bg-gray-700 hover:scale-105"
                      >
                        No
                      </button>
                    </div>
                  </div>
                </div>
              )}
              {/* !finalizarResponse && ( */}
              {status && !showCreateForm && (
                <div className="mt-4">
                  <h2 className="text-2xl mb-2 text-gray-600 font-semibold">
                    Finalizar turno activo
                  </h2>
                  <p className="text-md md:text-lg mb-5">
                    Antes de comenzar un turno, primero tenes que terminar
                    cualquier turno que esté corriendo en la actualidad. Agrega
                    el personal que trabajó en este turno para poder
                    finalizarlo.
                  </p>

                  {mozos.length > 0 ? (
                    <div className="overflow-visible">
                      <table className="table-auto w-full text-center">
                        <thead>
                          <tr>
                            <th></th>
                            <th>Trabajador</th>
                            <th>Rol</th>
                            <th className="flex items-center justify-center text-center">
                              Efectivo
                              <ToolTip
                                position="left"
                                width="w-80"
                                text="Activando esta opción, podrás determinar la cantidad de efectivo que registró cada trabajador (esta función es opcional)."
                              />
                            </th>
                            <th>Digital</th>
                            <th>Se queda</th>
                            <th>Runners y Cocina</th>
                          </tr>
                          <tr className="bg-gray-100">
                            <td className="py-4">
                              <input
                                type="checkbox"
                                onChange={handleSelectAllCheckboxChange}
                                checked={selectAllChecked}
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                value={nameFilter}
                                className="border p-2 shadow"
                                onChange={handleNameFilterChange}
                                placeholder="Buscar trabajador"
                              />
                            </td>
                            <td>
                              <select
                                className="p-1 shadow border"
                                value={selectedRole}
                                onChange={handleRoleFilterChange}
                              >
                                <option value="">Todos</option>
                                {rolesName.map((role) => (
                                  <option key={role} value={role}>
                                    {role}
                                  </option>
                                ))}
                              </select>
                            </td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                          </tr>
                        </thead>
                        <tbody>
                          {mozos
                            .flatMap((grupo) => grupo.usuarios)
                            .sort((a, b) =>
                              a.nombre
                                .toLowerCase()
                                .localeCompare(b.nombre.toLowerCase())
                            )
                            .filter((mozo) =>
                              mozo.nombre
                                .toLowerCase()
                                .includes(nameFilter.toLowerCase())
                            )
                            .filter(
                              (mozo) =>
                                selectedRole === "" || mozo.rol === selectedRole
                            )
                            .map((mozo) => (
                              <tr key={mozo._id} className="border-t">
                                <td className="p-1.5">
                                  <input
                                    type="checkbox"
                                    id={`checkbox-${mozo.cuit}`}
                                    checked={
                                      selectedMozos.includes(mozo.cuit) &&
                                      !unselectedMozos.includes(mozo.cuit)
                                    }
                                    className="form-checkbox"
                                    onChange={(e) =>
                                      handleMozosChange(
                                        e.target.checked,
                                        mozo.cuit
                                      )
                                    }
                                  />
                                </td>
                                <td className="p-1.5 w-20">
                                  <div className="flex items-center">
                                    <img
                                      src={mozo.foto}
                                      onError={(e) => {
                                        e.currentTarget.src =
                                          "./Tippit-logo-cuadrado-trasparente-300x300.png";
                                      }}
                                      className="rounded-full object-cover w-10 h-10 border-3 mr-2"
                                      alt={mozo.nombre}
                                      draggable="false"
                                    />
                                    {mozo.nombre}
                                  </div>
                                </td>
                                <td className="p-1.5">{mozo.rol}</td>
                                <td className="p-1.5">
                                  <input
                                    type="number"
                                    placeholder="$"
                                    className="border px-2 py-1 shadow hover:border-pink-500 w-20 "
                                    value={efectivoValues[mozo.cuit] || ""}
                                    onChange={(e) =>
                                      handleEfectivoValueChange(
                                        mozo.cuit,
                                        e.target.value
                                      )
                                    }
                                    disabled={!efectivoEnabled}
                                    onBlur={() => getTurnoActivo()}
                                    min="0"
                                  />
                                </td>
                                <td className="p-1.5">
                                  <input
                                    type="number"
                                    placeholder="$"
                                    className="border px-2 py-1 shadow hover:border-pink-500 w-20"
                                    value={
                                      parseFloat(
                                        digitalValues?.find(
                                          (item) => item.mozo_cuit === mozo.cuit
                                        )?.monto_total || 0
                                      ) || ""
                                    }
                                    disabled={!efectivoEnabled}
                                    readOnly
                                    min="0"
                                    step="0.01"
                                  />
                                </td>
                                <td className="p-1.5">
                                  <input
                                    type="number"
                                    placeholder="$"
                                    className="border px-2 py-1 shadow hover:border-pink-500 w-20"
                                    value={
                                      rendirValue(mozo) > 0
                                        ? rendirValue(mozo)
                                        : ""
                                    }
                                    disabled={!efectivoEnabled}
                                    readOnly
                                    min="0"
                                    step="0.01"
                                  />
                                </td>
                                <td className="p-1.5">
                                  <input
                                    type="number"
                                    placeholder="$"
                                    className="border px-2 py-1 shadow hover:border-pink-500 w-20"
                                    value={
                                      rcValue(mozo) > 0 ? rcValue(mozo) : ""
                                    }
                                    disabled={!efectivoEnabled}
                                    readOnly
                                    min="0"
                                    step="0.01"
                                  />
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  ) : (
                    <p className="w-full">
                      Por favor vincula mozos antes de utilizar esta
                      herramienta.
                    </p>
                  )}

                  <button
                    onClick={verificarTurno}
                    disabled={isFinalizeButtonDisabled()}
                    className="transition-all 
                          duration-300 
                          ease-in-out 
                          w-full 
                          px-2 
                          py-4 
                          disabled:bg-gray-500
                          text-white 
                          font-semibold 
                          bg-red-500 
                          rounded-lg 
                          hover:bg-red-800 "
                  >
                    {!isLoading ? (
                      "Finalizar Turno"
                    ) : (
                      <img
                        src="/gooey-balls-2.svg"
                        className="mx-auto filter invert"
                        draggable="false"
                        alt="cargando"
                      />
                    )}
                  </button>
                  <p className="text-red-700 text-center mt-1">{error}</p>
                </div>
              )}

              {showModal && unselectedMozos.length > 0 && (
                <div className="fixed inset-0 flex items-center justify-center bg-grey bg-opacity-10 z-50">
                  <div className="bg-white pt-6 shadow-lg text-left w-2/3 max-w-50 min-w-[155px] p-4">
                    <h3 className="text-lg mb-2 font-semibold">Atencion!</h3>
                    <p className="text-gray-400 text-md">
                      Hay personal no incluido en el cierre de turno que
                      participo en la propina digital. ¿Quieres incluirlas en el
                      siguiente turno?
                    </p>
                    <div className="flex flex-col m-5">
                      {unselectedMozos.map((nombre) => {
                        const mozo = mozos
                          .flatMap((mozos) => mozos.usuarios)
                          .find((m) => m.cuit === nombre); // Busca el mozo completo por nombre
                        return mozo ? ( // Solo renderiza si el mozo es encontrado
                          <div key={mozo.cuit} className="flex items-center">
                            <input
                              type="checkbox"
                              id={`checkbox-${mozo.cuit}-nextTurno`}
                              className="form-checkbox"
                              onChange={(e) =>
                                handleNextTurnoCheckboxChange(e, mozo.cuit)
                              }
                            />
                            <img
                              src={mozo.foto}
                              onError={(e) => {
                                e.currentTarget.src =
                                  "./Tippit-logo-cuadrado-trasparente-300x300.png";
                              }}
                              className="rounded-full object-cover w-10 h-10 border-3 mr-2 ml-2"
                              alt={mozo.nombre}
                              draggable="false"
                            />
                            <p className="font-bold text-lg">{mozo.nombre}</p>

                            <p className="text-gray-400 text-lg ml-5">
                              $
                              {digitalValues?.find(
                                (item) => item.mozo_cuit === mozo.cuit
                              )?.monto_total || 0}
                            </p>
                          </div>
                        ) : null; // Retorna null si no se encuentra el mozo
                      })}
                    </div>
                    <hr></hr>
                    <div className="text-center">
                      <button
                        onClick={() => {
                          setShowModal(false);
                          setNextMozos([]);
                        }}
                        className="transition-all duration-300 ease-in-out px-4 py-2 text-pink-500 font-semibold border-2 border-pink-500 rounded-lg hover:bg-white hover:scale-105 mt-4 mr-10"
                      >
                        Volver atras
                      </button>
                      <button
                        onClick={() => {
                          setShowModal(false);
                          //window.location.reload();
                          finalizarTurno();
                        }}
                        className={` text-white font-semibold rounded-lg px-4 py-2 ${
                          nextMozos.length === unselectedMozos.length
                            ? "transition-all duration-300 ease-in-out  bg-pink-500  hover:bg-pink-700 hover:scale-105"
                            : "bg-gray-500 text-white"
                        } mt-4"`}
                        disabled={nextMozos.length !== unselectedMozos.length}
                      >
                        Siguiente turno
                      </button>
                    </div>
                  </div>
                </div>
              )}

              {showModal && finalizarResponse && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-90 z-50">
                  <div className="bg-white pt-6 rounded-lg shadow-lg text-left w-6/11 max-w-90 min-w-[355px] p-4">
                    <h3 className="text-lg mb-2 font-semibold">
                      Resultado del turno finalizado:
                    </h3>
                    <h2 className="text-xl font-bold">Se genero:</h2>
                    <p className="text-gray-700 text-lg">
                      {Object.entries(finalizarResponse.efectivo_separado)
                        .map(([nombre, monto]) => ({
                          nombre,
                          total: (
                            parseFloat(monto) +
                            parseFloat(
                              finalizarResponse.digital_separado[nombre]
                            )
                          ).toFixed(2),
                        }))
                        .filter(({ total }) => parseFloat(total) !== 0)
                        .map(({ nombre, total }, index) => (
                          <span key={index} className="block">
                            {
                              mozos
                                .flatMap((grupo) => grupo.usuarios)
                                .find((m) => m.cuit === nombre).nombre
                            }{" "}
                            ha generado: ${total}
                          </span>
                        ))}
                    </p>
                    <hr></hr>
                    <h2 className="text-xl font-bold">
                      Distribucion entre los roles:
                    </h2>
                    <p className="text-gray-700 text-lg">
                      {finalizarResponse.porcentajes_correspondientes.map(
                        (detalle, index) => (
                          <span key={index} className="block">
                            {detalle.nombre_rol} ha generado: $
                            {detalle.monto.toFixed(2)}
                          </span>
                        )
                      )}
                    </p>
                    <hr></hr>
                    <p className="text-gray-700 text-lg">
                      Monto Total: ${finalizarResponse.total.toFixed(2)}
                    </p>
                    <div className="text-center">
                      <button
                        onClick={() => {
                          setShowModal(false);
                          window.location.reload();
                        }}
                        className="transition-all duration-300 ease-in-out px-4 py-2 text-white font-semibold bg-gray-500 rounded-lg hover:bg-gray-700 hover:scale-105 mt-4"
                      >
                        Cerrar
                      </button>
                    </div>
                  </div>
                </div>
              )}

              <CalendarTurnos />

              {sessionExpired && <SessionOut />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Turno;
