

export default function TurnoInfo({ turno }) {
    return (
        <div className="bg-white p-6 rounded-3xl border shadow-lg max-w-md mx-auto mb-3">
            <div className="flex items-center mb-6">
                <h2 className="text-xl font-semibold text-gray-800">Información del turno solicitado:</h2>
            </div>

            <div className="space-y-4">
                <InfoCard
                    title="Total de Propinas"
                    amount={turno.monto_total || 0}
                />
                <InfoCard
                    title="Total Efectivo"
                    amount={turno.monto_efectivo || 0}
                />
                <InfoCard
                    title="Total Digital"
                    amount={turno.monto_digital || 0}
                />
            </div>
        </div>
    )
}

function InfoCard({ title, amount, icon }) {
    //const isPositive = change >= 0
    return (
        <div className="bg-gray-50 p-4 rounded-2xl">
            <div className="flex justify-between items-center mb-2">
                <span className="text-gray-500">{title}</span>
            </div>
            <div className="flex justify-between items-end">
                <div className="flex items-center">
                    {icon}
                    <span className="text-2xl font-bold ml-2">${amount.toFixed(2)}</span>
                </div>
                {/* <span className={`text-sm ${isPositive ? 'text-green-500' : 'text-red-500'}`}>
                    {isPositive ? '+' : ''}{change}%
                    {isPositive ? ' ↑' : ' ↓'}
                </span> */}
            </div>
        </div>
    )
}