import React, { useState, useEffect } from "react";
import Menu from "../components/Menu";
import SessionOut from "../components/SessionOut";
import { StarFill } from "react-bootstrap-icons";
import Ranking from "../components/Ranking";
import { StatCard } from "../components/StatCard"
import { openDB } from "../utils/indexedDB";
import { fetchMonthlyData, fetchCurrentMonthData } from "../utils/restaurantDataGetter";




// Traemos los QRS aver cuales estan activos y cuales no
// Esto se actualiza si hay QRS activos/inactivos, hay que hacerlo en tiempo real mas adelante
const fetchQRsCount = async (
  token,
  setSessionExpired,
  setIsLoaded,
  setTotalQrsCount,
  setActiveQrsCount
) => {
  try {
    const response = await fetch("https://tippitqr.com/listar_QR_pagos", {
      method: "GET",
      headers: { Authorization: `Bearer ${token}` },
    });

    if (response.status === 401) {
      setSessionExpired(true);
      return;
    }

    const { qr_pagos = [] } = await response.json();
    const totalQrs = qr_pagos.length;
    const activeQrs = qr_pagos.filter((mozo) => !mozo.mozo.fin_atencion).length;

    setTotalQrsCount(totalQrs);
    setActiveQrsCount(activeQrs);
  } catch (error) {
    console.error("Error accediendo a QR pagos:", error);
  } finally {
    setIsLoaded(true);
  }
};

const MiRestaurante = () => {
  const [sessionExpired, setSessionExpired] = useState(false);
  const [monthlyTips, setMonthlyTips] = useState({});
  const [currentMonthData, setCurrentMonthData] = useState({
    total: 0,
    count: 0,
  });
  const [selectedRange, setSelectedRange] = useState("Último año");
  const [isLoaded, setIsLoaded] = useState(false);
  const [totalQrsCount, setTotalQrsCount] = useState(0);
  const [activeQrsCount, setActiveQrsCount] = useState(0);

  const token = sessionStorage.getItem("token");

  useEffect(() => {
    if (token) {
      const fetchData = async () => {
        const db = await openDB();
        const monthlyData = await fetchMonthlyData(token, db);
        const currentData = await fetchCurrentMonthData(token);
        console.log(currentData)
        console.log(monthlyData)
        setMonthlyTips(monthlyData);
        setCurrentMonthData(currentData);
      };

      fetchData();
    }
  }, [token]);

  useEffect(() => {
    fetchQRsCount(
      token,
      setSessionExpired,
      setIsLoaded,
      setTotalQrsCount,
      setActiveQrsCount
    );
  }, [token]);

  // CALCULANDO TODO
  // Aca calculamos los totales, conteos y promedios de calificaciones de propinas para distintos rangos de tiempo (3 meses, 6 meses, etc). Usamos getMonthData para acumular el total, la calificación promedio y el conteo de transacciones de los últimos 30 días, 3 meses, 6 meses o el último año. Fijarse que los ultimos 30 dias son los unicos datos que no se obtienen del IndexedDB, son los que salen del fetch, asi que estan fresquitos fresquitos. calculateTotal obtiene el monto total, calculateTransactionCount el número de transacciones y calculateAverageRating el promedio de todas las calificaciones. Si, hay algunas que estan en zero, esas no las contamos, pero eso es porque tenemos que inducir al usuario a dejar una calificacion al menos de 1 estrella (en el futuro, es mejor que siempre haya algun tipo de califacion por parte del usuario. Igualmente como les dije en la reunion, seria ilogico que alguien deje propina a un mozo que los atendio mal...) Entonces, getValidMonthIndex ajusta el índice del mes para que se mantenga en el rango de 1 a 12, teniendo en cuenta los "desplazamientos" hacia atrás o adelante y asi poder ajustar el numero real. El rating del mes actual lo obtenemos de fetchmonth.

  const getMonthData = (range) => {
    let total = 0;
    let totalRating = 0;
    let count = 0;
    let count0 = 0;

    const addMonthData = (month) => {
      total += monthlyTips[month]?.total || 0;
      totalRating +=
        (monthlyTips[month]?.averageRating || 0) * (monthlyTips[month]?.count || 0);
      count += monthlyTips[month]?.count || 0;
      count0 += totalRating !== 0 ? monthlyTips[month].count : 0
      //console.log(totalRating)
      //console.log(count0)
    };

    switch (range) {
      case "Últimos 30 días":
        total = currentMonthData.total || 0;
        totalRating =
          (currentMonthData.averageRating || 0) * (currentMonthData.count || 0);
        count = currentMonthData.count || 0;
        count0 += totalRating !== 0 ? count : 0
        break;
      case "Últimos 3 meses":
        total += currentMonthData.total || 0;
        totalRating +=
          (currentMonthData.averageRating || 0) * (currentMonthData.count || 0);
        count += currentMonthData.count || 0;
        count0 += totalRating !== 0 ? count : 0
        for (let i = 1; i <= 2; i++) {
          const month = getValidMonthIndex(-i);
          addMonthData(month);
        }
        break;
      case "Últimos 6 meses":
        total += currentMonthData.total || 0;
        totalRating +=
          (currentMonthData.averageRating || 0) * (currentMonthData.count || 0);
        count += currentMonthData.count || 0;
        count0 += totalRating !== 0 ? count : 0
        for (let i = 1; i <= 5; i++) {
          const month = getValidMonthIndex(-i);
          addMonthData(month);
        }
        break;
      case "Último año":
        total += currentMonthData.total || 0;
        totalRating +=
          (currentMonthData.averageRating || 0) * (currentMonthData.count || 0);
        count += currentMonthData.count || 0;
        count0 += totalRating !== 0 ? count : 0
        for (let i = 1; i <= 11; i++) {
          const month = getValidMonthIndex(-i);
          addMonthData(month)
        }
        break;
      default:
        break;
    }

    return { total, totalRating, count, count0 };
  };

  const calculateTotal = (range) => {
    const { total } = getMonthData(range);
    return Number(total).toFixed(2);
  };

  const calculateTransactionCount = (range) => {
    const { count } = getMonthData(range);
    return count;
  };

  const calculateAverageRating = (range) => {
    const { totalRating, count0 } = getMonthData(range);
    //console.log(count0)

    const validCount = count0 > 0 ? count0 : 1; // Evitar división por 0
    const validTotalRating = totalRating > 0 ? totalRating : 0;

    return validTotalRating > 0 ? (validTotalRating / validCount).toFixed(2) : 0;
  };

  const getValidMonthIndex = (monthOffset) => {
    const currentMonth = new Date().getMonth() + 1;
    let month = currentMonth + monthOffset;
    if (month < 1) month += 12;
    if (month > 12) month -= 12;
    return month;
  };


  // Okay, esto es re villero pero por ahora va a servir
  // estoy eliminando y agregando la clase fadeout cada vez que usamos el <SELECT> para 
  // esconder que estamos trayendo los datos de la indexDB.
  const handleSelectChange = (e) => {
    setSelectedRange(e.target.value);

    const fadeOutElements = document.querySelectorAll('.fade-out');

    fadeOutElements.forEach((element) => {
      element.classList.remove('fade-out');
    });

    setTimeout(() => {
      fadeOutElements.forEach((element) => {
        element.classList.add('fade-out');
      });
    }, 10);
  };


  return (
    <div className="w-full min-h-screen bg-gradient-to-b from-pink-500 via-pink-200 to-gray-300 md:bg-none">
      <div className="flex flex-col h-screen">
        <div className="relative block bg-gray-100">
          <div className="flex flex-1 overflow-auto">
            <Menu rol="Restaurante" token={token} />
            <div className="flex-1 w-full">
              <h1 className="block md:hidden text-xl bg-pink-500 text-white p-2 font-sans mt-0 pt-1 pl-10 mb-4">
                Mi Restaurante
              </h1>
              <div className="w-11/12 max-w-[900px] pt-8 lg:mt-10 px-8 pb-10 min-h-96 mx-auto bg-white overflow-hidden shadow-lg shadow-gray-250 rounded-lg relative mb-10">
                <div className="flex justify-between items-center mb-2">
                  <h1 className="text-2xl font-semibold hidden lg:block">
                    Mi Restaurante
                  </h1>
                  <select
                    className="px-2 py-1 rounded-md w-full lg:w-auto border"
                    value={selectedRange}
                    onChange={handleSelectChange}
                  >
                    <option>Últimos 30 días</option>
                    <option>Últimos 3 meses</option>
                    <option>Últimos 6 meses</option>
                    <option>Último año</option>
                  </select>
                </div>
                <hr className="my-5 mx-[-30px]" />
                <div className="flex space-x-4 mb-10">
                  <StatCard title="Monto Propina Total" id="calculateTotal">
                    <div className="text-5xl font-semibold text-gray-600 hover:text-black pt-2">
                      ${calculateTotal(selectedRange)}
                    </div>
                  </StatCard>
                  <StatCard title="QRs" id="calculateQrs">
                    <div className="text-4xl text-gray-600 hover:text-black">
                      <span className="font-semibold">{activeQrsCount}</span>/
                      <span className="text-gray-300">{totalQrsCount}</span>
                    </div>
                    <p className="text-sm text-gray-400">Activos</p>
                  </StatCard>
                  <StatCard title="Propinas" id="calculateTips">
                    <div className="text-4xl text-gray-600 hover:text-black">
                      {calculateTransactionCount(selectedRange)}
                    </div>
                    <p className="text-sm text-gray-400">Recibidas</p>
                  </StatCard>
                  <StatCard title="Servicio" id="calculateService">
                    <div className="flex flex-col items-center justify-center text-center w-full">
                      <div className="text-4xl text-gray-600 hover:text-black flex items-center justify-center">
                        <StarFill className="text-pink-500" width={25} height={25} />
                        <span className="ml-2">
                          {calculateAverageRating(selectedRange)}
                        </span>
                      </div>
                      <p className="text-sm text-gray-400">Promedio</p>
                    </div>
                  </StatCard>
                </div>
                <div className="flex space-x-4 ">
                  <StatCard title={"Mejor Rendimiento"} id={"rendimiento"}>
                    <Ranking tipo={"rendimiento"} range={selectedRange} />
                  </StatCard>
                  <StatCard title={"Mejor Propina"} id={"propina"}>
                    <Ranking tipo={"propina"} range={selectedRange} />
                  </StatCard>
                </div>
                {sessionExpired && <SessionOut />}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MiRestaurante;
