/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Menu from "../components/Menu";
import SessionOut from "../components/SessionOut";
import { Pencil, QrCode, XCircleFill } from "react-bootstrap-icons";

const MisQRS = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [token, setToken] = useState(null);
  const [mozos, setMozos] = useState([]);
  const [sessionExpired, setSessionExpired] = useState(false);
  const [selectedCuit, setSelectedCuit] = useState("");
  const [isLoaded, setIsLoaded] = useState(false);
  const [mozosVinculados, setMozosVinculados] = useState([]);

  const handleSelectChange = async (selectedCuit, mesa, mozoActualCuit) => {
    console.log("Selected Cuit:", selectedCuit);
    const selectedMozo = selectedCuit;
    const mozoActual = mozos.find(
      (mozo) => mozo.mesa === mesa && mozo.mozo.cuit === mozoActualCuit
    );
    console.log("Selected Mozo:", selectedMozo);
    console.log("Mesa:", mesa);
    console.log("Mozo Actual Cuit:", mozoActualCuit);

    if (selectedMozo && mozoActual) {
      try {
        const url = "https://tippitqr.com/mozo/activar";
        const params = new URLSearchParams();
        params.append("cuit", selectedMozo);
        params.append("token_padre", mozoActual.mozo.token_padre);
        params.append("token_db", mozoActual.mozo.token_db);

        // Que kilombo me hice con esteeee
        // console.log("URL:", url);
        // console.log("Params:", params.toString());

        const card = document.querySelector(`[data-id="${mesa}"]`);
        const details = card.querySelector(".details");
        details.classList.add("translate-x-[120%]");

        const response = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: `Bearer ${token}`,
          },
          body: params.toString(),
        });

        if (!response.ok) {
          throw new Error(`Error en la petición: ${response.statusText}`);
        }

        await fetchMozos();
      } catch (error) {
        console.error("Error en el manejo de la selección:", error);
      } finally {
        const card = document.querySelector(`[data-id="${mesa}"]`);
        const details = card.querySelector(".details");
      }
    } else {
      console.error("Mozo seleccionado o mozo actual no encontrado.");
    }
  };

  const handleCardClick = async (mesa, mozo) => {
    try {
      const url = mozo.fin_atencion
        ? "https://tippitqr.com/mozo/activar/"
        : "https://tippitqr.com/mozo/finalizar";
      const params = new URLSearchParams();
      params.append("cuit", mozo.cuit);
      params.append("token_padre", mozo.token_padre);
      params.append("token_db", mozo.token_db);

      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `Bearer ${token}`,
        },
        body: params.toString(),
      });

      if (!response.ok) {
        throw new Error("Error en la petición");
      }

      await fetchMozos(); // Actualizar la lista de mozos después de la petición
    } catch (error) {
      console.error("Error en el manejo del clic:", error);
    }
  };

  useEffect(() => {
    let currentToken = location.state?.token;
    if (!currentToken) {
      currentToken = sessionStorage.getItem("token");
    } else {
      sessionStorage.setItem("token", currentToken);
    }

    if (!currentToken) {
      navigate("/");
    } else {
      setToken(currentToken);
    }
  }, [navigate, location.state]);

  const fetchMozos = useCallback(async () => {
    try {
      const response = await fetch("https://tippitqr.com/listar_QR_pagos", {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 401) {
        setSessionExpired(true);
        return;
      }

      const data = await response.json();

      if (Array.isArray(data.qr_pagos)) {
        console.table(
          data.qr_pagos.map(({ mesa, mozo }) => ({
            Nombre: mozo.nombre,
            CUIT: mozo.cuit,
            Mesa: mesa,
            FinAtencion: mozo.fin_atencion ? "true" : "false",
            HoraFinAtencion: mozo.hora_fin_atencion || "No disponible",
            Foto: mozo.foto,
            TokenPadre: mozo.token_padre,
            TokenDB: mozo.token_db,
          }))
        );
        console.log("DATA", data.qr_pagos);
        setMozos(data.qr_pagos);
      }
    } catch (error) {
      console.error("Error accediendo a QR pagos:", error);
    } finally {
      setIsLoaded(true);
    }
  }, [token]);

  const uniqueMozos = Array.from(
    new Map(
      mozos
        .filter((mozo) => mozo.mozo.nombre && mozo.mozo.cuit)
        .map((mozo) => [mozo.mozo.cuit, mozo])
    ).values()
  );

  useEffect(() => {
    if (token) {
      fetchMozos();
    }
  }, [token, fetchMozos]);

  const fetchMozosVinculados = useCallback(async () => {
    try {
      const response = await fetch(
        "https://tippitqr.com/userdb/mozos_vinculados",
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 401) {
        setSessionExpired(true);
        return;
      }

      const data = await response.json();

      // Extraer todos los usuarios sin importar el rol
      const mozos = data.reduce((acc, item) => {
        if (item.usuarios) {
          acc.push(...item.usuarios);
        }
        return acc;
      }, []);

      console.log("MIRA" + mozos);

      setMozosVinculados(mozos);
    } catch (error) {
      console.error("Error accediendo a mozos vinculados:", error);
    }
  }, [token]);

  useEffect(() => {
    if (token) {
      fetchMozosVinculados();
    }
  }, [token, fetchMozosVinculados]);

  return (
    <div className="w-full min-h-screen bg-gradient-to-b from-pink-500 via-pink-200 to-gray-300 md:bg-none">
      <div className="flex flex-col h-screen">
        <div className="relative block bg-gray-100">
          <div className="flex flex-1 overflow-auto">
            <Menu rol="Restaurante" token={token} />
            <div className="flex-1 w-full">
              <h1 className="block md:hidden text-xl bg-pink-500 text-white p-2 font-sans mt-0 pt-1 pl-10 mb-4">
                Mis QRS
              </h1>
              <div className="w-11/12 max-w-[900px] pt-8 lg:mt-10 px-8 pb-10 min-h-96 mx-auto bg-white overflow-hidden shadow-lg shadow-gray-250 rounded-lg relative mb-10">
                <h1 className="text-2xl font-semibold mb-2 hidden lg:block">
                  Mis QRS
                </h1>
                <p className="text-md md:text-lg">
                  Desde acá podés activar y desactivar los QRs de cada uno de tu
                  personal.
                </p>
                <hr className="my-5 mx-[-30px]" />
                <div>
                  {mozos.length > 0 ? (
                    <div className="flex flex-wrap gap-4 justify-center">
                      {mozos
                        .map((item) => (
                          <div
                            key={item.mesa}
                            data-id={item.mesa}
                            className={`card relative bg-white shadow-lg overflow-hidden rounded-lg p-2 w-[200px] text-center transition-transform transform hover:scale-105 hover:shadow-xl cursor-pointer select-none ${
                              item.mozo.cuit === null
                                ? "grayscale pointer-events-none"
                                : ""
                            }${
                              item.mozo.fin_atencion
                                ? "border-4 border-white scale-down"
                                : "border-4 border-green-600 scale-up"
                            }`}
                            onClick={() =>
                              handleCardClick(item.mesa, item.mozo)
                            }
                          >
                            <div className="text-left flex items-center justify-between">
                              <div className="flex items-center">
                                <QrCode
                                  className="mr-2"
                                  width={20}
                                  height={20}
                                />
                                <h2 className="text-xl font-bold">
                                  {item.mesa}
                                </h2>
                              </div>
                              {item.mozo.fin_atencion && (
                                <Pencil
                                  className="ml-auto cursor-pointer opacity-50 hover:opacity-100 transition-opacity duration-200"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    const card =
                                      e.currentTarget.closest(".card");
                                    const details =
                                      card.querySelector(".details");
                                    details.classList.toggle(
                                      "translate-x-[120%]"
                                    );
                                  }}
                                />
                              )}
                            </div>

                            <h3
                              className={`text-lg text-white mb-5 rounded-md overflow-hidden ${
                                !item.mozo.fin_atencion
                                  ? "bg-green-700"
                                  : "bg-red-600"
                              }`}
                            >
                              {!item.mozo.fin_atencion ? "Activo" : "Inactivo"}
                            </h3>
                            <img
                              src={
                                item.mozo.foto ||
                                "./Tippit-logo-cuadrado-trasparente-300x300.png"
                              }
                              className={`rounded-full w-[80px] h-[80px] mx-auto ${
                                item.mozo.fin_atencion ? "grayscale" : ""
                              }`}
                              alt={item.mozo.nombre}
                              draggable="false"
                            />

                            <label className="text-gray-700 capitalize block mt-2">
                              {item.mozo.nombre}
                            </label>

                            <div
                              className="details bg-pink-500 absolute inset-0 shadow-lg rounded-lg p-4 transform translate-x-[120%] transition-transform"
                              onClick={(e) => e.stopPropagation()}
                            >
                              <h3 className="text-white mb-1">Reasignar QR</h3>

                              <select
                                value={selectedCuit}
                                onChange={(e) =>
                                  setSelectedCuit(e.target.value)
                                }
                                className="w-full mb-4 p-2 border border-gray-300 rounded-md"
                              >
                                <option value="">Elegi a un personal</option>
                                {mozosVinculados
                                .sort((a,b) => a.nombre.toLowerCase().localeCompare(b.nombre.toLowerCase()))
                                .map((mozo, index) =>
                                  mozo && mozo.cuit && mozo.nombre ? (
                                    <option
                                      key={`${mozo._id}-${index}`}
                                      value={mozo.cuit}
                                    >
                                      {mozo.nombre}
                                    </option>
                                  ) : null
                                )}
                              </select>

                              <button
                                className={`bg-white text-black px-4 py-2 rounded-md ${
                                  selectedCuit
                                    ? "cursor-pointer"
                                    : "cursor-not-allowed opacity-50"
                                }`}
                                onClick={() =>
                                  selectedCuit &&
                                  handleSelectChange(
                                    selectedCuit,
                                    item.mesa,
                                    item.mozo.cuit
                                  )
                                }
                                disabled={!selectedCuit}
                              >
                                Asignar QR
                              </button>

                              <p
                                className="text-sm mt-6 text-white underline w-full hover:text-gray-400 cursor-pointer"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  const card = e.currentTarget.closest(".card");
                                  const details =
                                    card.querySelector(".details");
                                  details.classList.add("translate-x-[120%]");
                                }}
                              >
                                <XCircleFill
                                  width={23}
                                  height={23}
                                  className="mx-auto text-white duration-200 opacity-70 hover:opacity-100"
                                />
                              </p>
                            </div>
                          </div>
                        ))}
                    </div>
                  ) : (
                    <p>No hay personal disponibles.</p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {sessionExpired && <SessionOut />}
    </div>
  );
};

export default MisQRS;
