import { useCallback, useState } from "react"
import {fetchWeeklyData, fetchRoles, fetchTurnoActivo} from "../utils/restaurantDataGetter"

//TODO: completar este customHook
export function useRestaurant({ token }){
    const [mesaData, setMesaData] = useState(null)
    const [sessionExpired, setSessionExpired] = useState(false)
    const [roles, setRoles] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const [turnoActivo, setTurnoActivo] = useState(null)

    const getWeeklyData = useCallback(async () => {
        try{
            const newMesas = await fetchWeeklyData(token);

            setMesaData(newMesas)
        } catch (e){
            if (e.message.includes("401")){
                setSessionExpired(true)
            }
        }
    },[token])

    const getRolesData = useCallback(async () => {
        try{
            setIsLoading(true)
            const newRoles = await fetchRoles(token);
            setRoles(newRoles)
        } catch (e){
            if (e.message.includes("401")){
                setSessionExpired(true)
            }
        } finally {
            setIsLoading(false)
        }
    }, [token])

    const getTurnoActivo = useCallback(async () => {
        setIsLoading(true)
        try{
            const newTurnos = await fetchTurnoActivo(token);
            setTurnoActivo(newTurnos[0])
        } catch (e){
            if (e.message.includes("401")){
                setSessionExpired(true)
            }
        } finally {
            setIsLoading(false)
        }
    }, [token])
    return {mesaData, getWeeklyData, sessionExpired, getRolesData, roles, isLoading, getTurnoActivo, turnoActivo}
}