import { useCallback, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function PaymentInstructions({ efectivo, digital }) {
  const location = useLocation();
  const currentToken = location.state?.token;
  const [mozos, setMozos] = useState([]);
  const fetchMozos = useCallback(async () => {
    try {
      const response = await fetch(
        "https://tippitqr.com/userdb/mozos_vinculados",
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${currentToken}`,
          },
        }
      );

      const data = await response.json();
      if (response.ok) {
        setMozos(data.flatMap((grupo) => grupo.usuarios));
      } else {
        console.log("Error fetching mozos:", data);
      }
    } catch (error) {
      console.error("Error fetching mozos:", error);
    }
  }, [currentToken]);

  useEffect(() => {
    fetchMozos();
  }, [fetchMozos]);

  const buscarNombre = (cuit) => {
    return mozos.find((m) => m.cuit === cuit)?.nombre;
  };

  return (
    <div className="max-w-full sm:max-w-2xl mx-auto p-4 sm:p-6">
      {efectivo && digital && mozos.length > 0 && (
        Object.entries(efectivo)
        .map(([nombre, monto]) => ({
          nombre,
          efectivo: parseFloat(monto),
          digital: parseFloat(digital[nombre] || 0),
          total: parseFloat(monto) + parseFloat(digital[nombre] || 0)
        }))
        .filter(({ total }) => total !== 0)
        .map(({ nombre, efectivo, digital }, index) => (
          <div key={index} className="flex items-center justify-between flex-wrap space-y-2 sm:space-y-0 p-4 bg-white border border-gray-200 rounded-xl w-full mb-4">
            <div className="flex-grow flex justify-between">
              <p className="text-sm sm:text-medium text-gray-600 mt-1 py-1">
                <span className="font-bold">{buscarNombre(nombre) ? buscarNombre(nombre) : nombre }</span> generó, en total, los siguientes importes:
              </p>
              {efectivo !== 0 && (
              <div className="inline-block max-w-xs mx-auto px-4 py-2 mt-0 text-base font-medium rounded-full shadow bg-green-100 text-green-800">
                ${efectivo} en efectivo
              </div>
              )}
              {digital !== 0 && (
                <div className="inline-block max-w-xs mx-auto px-4 py-2 mt-0 text-base font-medium rounded-full shadow bg-blue-100 text-blue-800"> 
                  ${digital} en digital
                </div>
              )}
            </div>
          </div>
        ))
      )}
    </div>
  );
}